@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  font-family: 'SpaceMonoRegular', monospace;
}

@font-face {
  font-family: 'SpaceMonoBold';
  src: url('./fonts/SpaceMono-Bold.ttf');
}

@font-face {
  font-family: 'SpaceMonoBoldItalic';
  src: url('./fonts/SpaceMono-BoldItalic.ttf');
}

@font-face {
  font-family: 'SpaceMonoItalic';
  src: url('./fonts/SpaceMono-Italic.ttf');
}

@font-face {
  font-family: 'SpaceMonoRegular';
  src: url('./fonts/SpaceMono-Regular.ttf');
}
